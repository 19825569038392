.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-body {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Card-Header {
    margin-top: 50px;
}

.Card {
    width: 100%;
    margin-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

a {
    text-decoration: none;
  }
