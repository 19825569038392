.dzu-dropzone {
  padding: 40px !important;
  overflow: auto !important;
  border: 1px solid #EBEBEB !important;
  border-radius: 0px !important;
  margin-bottom: 20px;
}

.dzu-inputLabel {
  position: static !important;
  font-family: "Baloo Chettan 2" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #FFFFFF !important;
  padding: 8px 16px !important;
  border-radius: 0px !important;
  background-color: #d32828 !important;
}

.dzu-inputLabelWithFiles {
  display: none !important;
}

.zone {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: "Baloo Chettan 2";
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
  }

  &__formats {
    color: #ADADAD;
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
  }
}
